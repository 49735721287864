<template>
  <div class="done">
    <div class="top">
      <!-- <div class="line">
        <div class="left">随访类型：{{form.followUpTypeName}}</div>
        <div class="item ">随访名称：{{form.followUpName}}</div>
      </div>
      <div class="line mt10">
        <div class="left" >随访分类：{{form.contentTypeName}}
          {{form.contentTypeSecName?('~ '+form.contentTypeSecName):' '}}
          {{form.contentTypeThreeName?(' ~ '+form.contentTypeThreeName):' '}}
          {{form.contentTypeFourName?(' ~ '+form.contentTypeFourName):' '}}
          {{form.contentTypeFiveName?(' ~ '+form.contentTypeFiveName):''}}
          </div>
        <div class="item ">要求完成时间：{{form.completionTime}}</div>

      </div>-->
      <el-alert :title="`您正在查看${form.followUpName}的完成情况`" type="warning"></el-alert>
    </div>
    <!-- <div class="heng"></div>
    <div class='search-list'>

    <el-input placeholder="输入关键字" v-model="key" style="width: 500px;">
          <el-select
            placeholder="请选择查询字段"
            slot="prepend"
            v-model="select"
            clearable
            style="width:150px;"
          >
            <el-option
              v-for="(item,index) in [{name:'姓名',value:1},{name:'手机号',value:2},{name:'身份证号',value:3}]"
              :key="index"
              :value="item.value"
              :label="item.name"
            ></el-option>
          </el-select>
          <el-button slot="append" @click="getPatience" icon="el-icon-search"></el-button>
        </el-input>
      <div class="label ml20">发送时间：</div>
      <el-date-picker
        v-model="dateTime"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        format="yyyy-MM-dd " value-format="yyyy-MM-dd">
      </el-date-picker>
      <div class="label ml20">完成状态：</div>
      <el-select
          placeholder="请选择状态"
          v-model="status"
          clearable
          style="width:200px;"
        >
          <el-option
            v-for="(item,index) in [{name:'已完成',value:1},{name:'未完成',value:2}]"
            :key="index"
            :value="item.value"
            :label="item.name"
          ></el-option>
      </el-select>
       <el-button type='primary' class='ml20' @click='getPatience'>查询</el-button>
      <el-button class=' ml20' @click='reset'>重置</el-button>
    </div>
    <div class="heng"></div>-->
    <el-table class="table mt20" :data="list" style="width: 100%">
      <el-table-column prop="contentTypeName" :label="type==1?'问卷分类':'宣教分类'"></el-table-column>
      <el-table-column prop="contentName" :label="type==1?'问卷分类':'宣教分类'"></el-table-column>
      <el-table-column prop="completedNumber" label="完成人数"></el-table-column>
      <!-- <el-table-column prop='isAnswer' label='完成状态' header-align="center" align="center" > 
          <template slot-scope="scope">
            <div v-if='type==1'>

            {{scope.row.isAnswer==1?'已完成':'未完成'}}
            </div>
            <div v-else>
            {{scope.row.isRead==1?'已完成':'未完成'}}

            </div>
          </template>
        </el-table-column>
      <el-table-column prop='endTime' label='完成时间' header-align="center" align="center" > </el-table-column>-->

      <el-table-column label="操作" header-align="right" align="right" fixed="right">
        <template slot-scope="scope">
			<el-dropdown
			   >
			     <span class="el-dropdown-link">
			          <el-button type="text" size="small"  >导入</el-button><i style="color: #4099ff;" class="el-icon-arrow-down el-icon--right"></i>
			     </span>
			     <el-dropdown-menu slot="dropdown">
			       <el-dropdown-item @click.native="handleClick('0',scope.row.contentId)" >下载模板</el-dropdown-item>
								  <el-upload
								    class="upload-demo"
								    :action="url+'&infoId='+ scope.row.contentId+'&uuid='+ scope.row.uuid"
								    :show-file-list="false"
								    :on-success="importSuccess"
								    :headers="importHeader"
								  >
								    <el-dropdown-item >导入数据</el-dropdown-item>
								  </el-upload>
			       
			     </el-dropdown-menu>
			   </el-dropdown>
          <el-button style="margin-left: 15px;" type="text" size="small" @click="goToInfo(scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
	    <importFail v-if="importFailVisible" ref="importFail" :showData="showData"></importFail>
		
  </div>
</template>
<script>
import Qs from "qs";
import importFail from "@/components/importFail";
export default {
  name: "done",
  data() {
    return {
      programName: "",
      type: "",
	  importHeader: {
	    token: localStorage.getItem("token"),
	  },
	     url: "", //导入链接
      form: "",
      list: [],
      key: "",
	  showData: "",
	  importFailVisible: false,
      select: "",
      dataListLoading: true, //列表loading
      dataListSelections: [], //选中的列
      pageNo: 1,
      pageSize: 10,
      totalPage: 0, //总页数
      dateTime: ["", ""],
      status: "",
    };
  },
  components: {
   
    importFail,
  },
  mounted() {

	  this.url = this.$global.baseURL+'/vfWriteInfo/importWriteInfo?teamId='+ JSON.parse(localStorage.currentTeam).id
    let query = this.$route.query;
    // this.number = query.num
    if (query.ids) {
      this.dataListLoading = true;
      this.type = query.type;
      this.name = query.name;
      this.init(query.ids, query.type, query.name);
    }
  },
  methods: {
    async init(ids, type, name) {
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/followUp/getFollowUpFeedbackByContentIds"),
        method: "post",
        data: {
          stringParam2: ids,
          // followUpType:type,
          stringParam1: name,
        },
      });
      console.log(res, "res info");
      if (res.status) {
        this.form = res.data[0];
        this.list = res.data;
        // this.list = res.data
        // let name = ''
        // res.data.forEach(v=>{
        //   name+= v.programName + '，'
        // })

        // this.programName = name.slice(0,name.length-1)
        // console.log(res.data[0].followUpName);
        // await this.getPatience()
      } else {
        this.$message.error("获取失败，请重试");
      }
    },
    async getPatience() {
      // console.log(type,name,'pas');
      //  type = type==''?this.type:type
      //  console.log(type);
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/followUp/getPatienceByFollowUpName"),
        method: "post",
        data: {
          stringParam2: this.form.followUpName,
          stringParam1: this.type,
          stringParam3: this.select == 1 ? this.key : "",
          stringParam5: this.select == 2 ? this.key : "",
          stringParam4: this.select == 3 ? this.key : "",
          stringParam6: this.dateTime[0],
          stringParam7: this.dateTime[1],
          intParam3: this.status,
          intParam1: this.pageSize,
          intParam2: this.pageNo,
        },
      });
      console.log(res, "res info");
      if (res.status) {
        this.list = res.data;
        this.totalPage = res.totalCount;
      } else {
        this.$message.error("获取失败，请重试");
      }
    },
    reset() {
      this.status = "";
      this.key = "";
      this.select = "";
      this.dateTime = ["", ""];
    },
    goToInfo(row) {
      this.$router.push({
        path: "/followUpList/info",
        query: {
          id: row.contentId,
          followUpName: this.form.followUpName,
          type: this.type,
          name: row.contentName,
          followUpType: row.followUpType,
        },
      });
      // let href =
      //   this.$global.dominH5 +
      //   "dataCollectInfo2.html?id=" +
      //   this.form.contentId;
      // window.open(href);
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getPatience();
    },
	async	handleClick(index,id){
		if(index==0){
			var a = document.createElement("a");
			a.href = this.$global.baseURL + '/vfTableInfo/getExcelExport?id='+id;
			console.log(a.href )
			a.download = "";
			a.click();
		
		}else{
			
		}
			
		},
	importSuccess(response, file, fileList) {
	  console.log(response);
	  if (response.status) {
	    this.$message({
	      message: "操作成功",
	      type: "success",
	      duration: 1500,
	      onClose: () => {
	        this.dialogVisible = false;
	        this.dataListSelections2.push(response.data[0]);
	        console.log(this.dataListSelections2);
	        // this.getList()
	        // this.getSchoolList2()
	      },
	    });
	  } else {
	    if (response.data && response.data.length != 0) {
	      this.showData = response.data;
	      this.importFailVisible = true;
	      this.$nextTick(() => {
	        this.$refs.importFail.init();
	      });
	    } else {
	      this.$message.error(response.msg + ",请重试");
	      this.dialogVisible = false;
	    }
	  }
	},
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getPatience();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.done {
  .search-list {
    display: flex;
    align-items: center;
    .label {
      width: 5%;
    }
  }
  .top {
    font-size: 20px;
    .line {
      display: flex;
    }
    .left {
      width: 80%;
    }
    .item {
      width: 20%;
    }
  }
  .heng {
    width: 100%;
    height: 1px;
    background: #eee;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>